<template>
  <v-row>
    <v-col cols="11">
      <v-autocomplete
        :chips="chips"
        :filled="filled"
        :items="selectValues"
        :label="label || keyAttr"
        :multiple="multipleSelection"
        :hint="attribute.getHelp()"
        item-text="name"
        item-value="id"
        menu-props="auto"
        persistent-hint
        :value-comparator="compareValue"
        v-model="valueAttribute"
        :loading="isCompletudeRequired(bindAttribute, currentIssues)"
      >
        <template v-slot:selection="{ item }">
          <v-chip>
            <value-marketplace v-model="item" />
          </v-chip>
        </template>
        <template v-slot:item="{ item, on, attrs }">
          <v-simple-checkbox
            v-if="multipleSelection"
            v-on="on"
            :disabled="attrs.disabled"
            :value="attrs.inputValue"
            :ripple="attrs.ripple"
          />
          <value-marketplace v-model="item" />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="1">
      <dialog-langs v-if="!isEmpty(valueAttribute)" v-model="valueAttribute" :items="itemsValue">
      </dialog-langs>
    </v-col>
  </v-row>
</template>
<script>
import AttributeBaseMixins from '@/mixins/attribute-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import LangBaseMixins from '@/mixins/lang-base';
import ValueMarketplace from '@/components/helper/ValueMarketplace';

const namespace = 'components.attribute.select_lang';
export default {
  mixins: [LangBaseMixins, AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeSelection',
  props: {
    itemsValue: {
      type: Array,
      required: false,
    },
  },
  components: {
    ValueMarketplace,
  },
  computed: {
    valueAttribute: {
      get() {
        const properties = this.item[this.property];
        if (properties && typeof properties[this.keyAttr] !== 'undefined') {
          try {
            if (this.multipleSelection && Array.isArray(properties[this.keyAttr])) {
              if (typeof properties[this.keyAttr][0].fr !== 'undefined') {
                return properties[this.keyAttr].map(v => v.fr);
              }
              return properties[this.keyAttr];
            }
            return this.convertIfArrayNeeded(
              properties[this.keyAttr].fr || properties[this.keyAttr],
            );
          } catch (err) {
            this.$log.error(
              `${namespace}.valueattribute.${this.keyAttr}.cant.be.retrieved.correctly`,
            );
            return null;
          }
        }
        throw new TypeError(`${namespace}.valueattribute.cant.be.retrieved`);
      },
      set(value) {
        if (this.isEmpty(value)) {
          this.item[this.property][this.keyAttr] = '';
        } else if (this.multipleSelection && Array.isArray(value) && value.length > 0) {
          this.item[this.property][this.keyAttr] = value.reduce((result, val) => {
            const index = this.itemsValue.findIndex(v => v.id.slugify() === val.slugify());
            if (index === -1) {
              this.$log.error(`${namespace}.valueattribute.cant.be.${val}.setted`);
            } else {
              const itemValue = { ...this.itemsValue[index] };
              result.push(itemValue.name);
            }
            return result;
          }, []);
        } else {
          const index = this.itemsValue.findIndex(v => v.id.slugify() === value.slugify());
          if (index === -1) {
            throw new TypeError(`${namespace}.valueattribute.cant.be.setted`);
          }
          try {
            const itemValue = { ...this.itemsValue[index] };
            this.item[this.property][this.keyAttr] = itemValue.name;
          } catch (err) {
            this.$log.error(err);
            throw new TypeError(`${namespace}.valueattribute.have.failed`);
          }
        }
      },
    },
  },
  data: () => ({
    type: 'selection',
  }),
  methods: {
    isEmpty(values) {
      if (typeof values === 'string' && !values) return true;
      if (Array.isArray(values) && values.length === 1 && typeof values[0] === 'string' && !values[0]) return true;
      if (Array.isArray(values) && values.length === 0) return true;
      return false;
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row align="center" dense>
      <v-col cols="11" md="11" align="center">
        <v-chip :color="getColor(variant.quable)" dark small>
          {{ getMessage(variant.quable) }}
        </v-chip>
        <v-chip :color="variant.sealed ? 'red' : 'black'" dark small>
          {{ variant.sealed ? "sealed" : "non-sealed" }}
        </v-chip>
        <v-chip :color="variant.active ? 'red' : 'black'" dark small>
          {{ variant.active ? "active" : "non-active" }}
        </v-chip>
        <v-chip color="green" dark small v-if="isSuperAdmin"> pim.id:{{ variant.id }} </v-chip>
        <v-chip color="green" dark small v-if="isSuperAdmin"> pim.remotecode:{{ variant.remoteCode }} </v-chip>
        <v-chip v-if="variant.legacyId" color="green" dark small>
          marketplace.id:{{ variant.legacyId }}
        </v-chip>
        <v-chip color="orange" dark small v-if="isSuperAdmin"> pim.createdat:{{ variant.createdAt }} </v-chip>
        <v-chip color="orange" dark small> pim.updatedat:{{ variant.updatedAt }} </v-chip>
      </v-col>
      <v-col cols="1" md="1" align="center">
        <dialog-list-options
          v-if="listOptions.length > 1"
          v-model="dialogListOption"
          :list-options="listOptions"
          :current="variant.copy()"
          x-large
        >
          <template v-slot:title>
            {{ variant.name }}<span v-if="variant.sealed"> (data sealed)</span>
          </template>
        </dialog-list-options>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-text-field v-model="variant.name" label="Variant name" append-icon="mdi-cancel" readonly  v-if="isSuperAdmin">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="familyName"
          append-icon="mdi-cancel"
          readonly
          label="Family attribute name"
          :loading="isCompletudeRequired('classificationId', currentIssues)"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="variant.season"
          :items="collections"
          menu-props="auto"
          hide-details
          :item-text="item => `${item.name} (${item.season})`"
          item-value="season"
          label="Select your season"
          :loading="isCompletudeRequired('season', currentIssues)"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="variant.supplierReference"
          :append-icon="userCanAccess ? undefined : 'mdi-cancel'"
          :readonly="!userCanAccess"
          label="Supplier Reference Color"
          :loading="isCompletudeRequired('supplierReference', currentIssues)"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-if="colors"
          v-model="variant.color"
          :items="buildRawValues(colors || [])"
          hide-details
          :append-icon="userCanAccess ? undefined : 'mdi-cancel'"
          :readonly="!userCanAccess"
          item-text="name"
          item-value="id"
          label="Variant color"
          :value-comparator="compareValue"
          :loading="isCompletudeRequired('color', currentIssues)"
        >
          <template v-slot:selection="{ item }">
            <v-chip>
              <value-marketplace v-model="item" />
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <value-marketplace v-model="item" />
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="variant.pattern"
          :append-icon="userCanAccess ? undefined : 'mdi-cancel'"
          :readonly="!userCanAccess"
          label="Variant Pattern"
          hint="🚫 Should be the same like specific color or pattern"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-tabs vertical background-color="indigo" dark>
          <v-tab
            v-for="(attribute, key) in filterAttributes"
            :class="fixClassOrderAttributeGroup(attribute.name)"
            :key="key"
          >
            {{ attribute.name }}
          </v-tab>
          <v-tab-item v-for="(attribute, key) in filterAttributes" :key="key">
            <dialog-json :item="attribute" property="dataJson"> </dialog-json>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="space-between" v-if="withSku" dense>
      <v-col cols="12">
        <v-subheader>
          Skus: (example: 42, 40, X, XL or with ean values 42:2121212121212)
        </v-subheader>
        <attribute-combo v-model="variant.skusValues"> </attribute-combo>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/helpers/event-bus';
import { Variant, VariantManager } from '@/models';
import ChipBaseMixins from '@/mixins/chip-base';
import ProfileBaseMixins from '@/mixins/profile-base';
import NotificationBaseMixins from '@/mixins/notification-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import LangBaseMixins from '@/mixins/lang-base';
import ValueMarketplace from '@/components/helper/ValueMarketplace';
import { buildRawValues } from '@/utils/rawvalue';

export default {
  name: 'VariantEdit',
  mixins: [
    ProfileBaseMixins,
    ChipBaseMixins,
    NotificationBaseMixins,
    CompletudeBaseMixins,
    LangBaseMixins,
  ],
  props: {
    value: {
      type: [Variant, Object],
      default: () => Variant.create(),
    },
    withSku: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValueMarketplace,
  },
  data: () => ({
    archive: null,
    dialogListOption: false,
    listOptionsDefault: [
      {
        name: 'Default',
      },
      {
        name: 'Change color 🟥🟧🟨🟩🟦🟪⬛️',
        fields: [
          'id',
          'color',
          'att_couleur_detail_rc',
          {
            customTemplate: 'checkbox',
            name: 'unsealed',
            label: 'Unsealed this variant',
          },
        ],
        store: 'variant/changeColor',
        scope: 'pimapp.v2:pim:variant:changecolor:write',
        payload: {
          children: true,
        },
      },
      {
        name: 'Change Family 🧥🥼👙👖👠🩴',
        fields: [
          'id',
          {
            customTemplate: 'family',
            name: 'familyId',
            label: 'Change this variant',
          },
        ],
        store: 'variant/changeFamily',
        scope: 'pimapp.v2:pim:variant:changefamily:write',
      },
      {
        name: 'Move this variant',
        fields: [
          'id',
          {
            customTemplate: 'product',
            name: 'productId',
            label: 'Choose your new product parent',
          },
        ],
        store: 'variant/move',
        scope: 'pimapp.v2:pim:variant:move:write',
        payload: {
          children: true,
        },
      },
      {
        name: 'Duplicate this variant 👯‍♀️👯‍♂️',
        fields: ['id', 'color', 'att_couleur_detail_rc', 'supplierReference'],
        store: 'variant/duplicate',
        scope: 'pimapp.v2:pim:variant:duplicate:write',
        payload: {
          children: true,
        },
      },
    ],
  }),
  watch: {
    variant: {
      deep: true,
      handler() {
        this.$emit('input', this.variant);
      },
    },
    familyId() {
      // update only if theses are filled
      if (this.variant && this.currentFamily) {
        VariantManager.saveFamily(this.variant, this.currentFamily);
        Vue.delete(this.variant, 'previousFamily');
        EventBus.$emit('import.importid.edit:reload');
      }
      this.openDialog = this.dialog;
    },
  },
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    variant: {
      get() {
        return this.value;
      },
      set(value) {
        this.value = value;
      },
    },
    ...mapGetters('form/rules', { textRules: 'text' }),
    ...mapGetters('collection', ['collections']),
    families() {
      return this.$store.getters['api/list']('family');
    },
    currentFamily() {
      if (this.variant && this.variant.familyId) {
        const index = this.families.findIndex(f => f.id === this.variant.familyId);
        return index !== -1 ? this.families[index] : false;
      }
      return false;
    },
    userCanAccess() {
      return this.hasRight('pimapp.v2:pim:variant:edit:write');
    },
    familyId: {
      get() {
        return this.variant.familyId;
      },
      set(value) {
        if (this.archive === null) {
          // fall back if cancel
          // this.archive = Object.assign([], this.variant.attributes);
        }
        // to identify if it s an updating or a creation
        Vue.set(this.variant, 'previousFamily', this.variant.familyId);
        this.variant.familyId = value;
      },
    },
    familyName() {
      if (!this.families || this.families.length === 0) {
        return '';
      }

      const index = this.families.findIndex(f => f.id === this.familyId);
      return index !== -1 ? this.families[index].name : '';
    },
    filterAttributes() {
      this.$log.log(`components.pim.variantedit.${this.variant.id}.started`);
      const { attributes } = this.variant;
      if (!attributes) {
        this.$log.error(`components.pim.variantedit.${this.variant.id}.attributes.empty`);
        return [];
      }
      if (!this.currentFamily) {
        this.$log.error(`components.pim.variantedit.${this.variant.id}.family.not.found`);
        return [];
      }
      VariantManager.updateFamily(this.variant, this.currentFamily);
      return this.variant.attributes;
    },
    listOptions() {
      return this.listOptionsDefault.filter(o => this.hasRight(o.scope || null));
    },
    colors() {
      const attr = this.$store.getters['api/attribute/attribute']('color');
      if (!attr) return [];
      return attr.values;
    },
  },
  mounted() {
    this.apiFamily();
    this.apiCollection();
  },
  methods: {
    ...mapActions('api', {
      apiFamily: 'family',
    }),
    ...mapActions('collection', { apiCollection: 'list' }),
    fixClassOrderAttributeGroup(groupName) {
      const groupsOrder = ['informations', 'détail', 'dimensions', 'composition', 'autres'];
      const index = groupsOrder.findIndex(g => groupName.toLowerCase().indexOf(g) === 0);
      if (index !== -1) {
        return `order-${index + 1}`;
      }
      return 'order-last';
    },
    compareValue(selected, current) {
      if (![selected, current].every(v => typeof v === 'string')) {
        return false;
      }
      return selected.slugify() === current.slugify();
    },
    buildRawValues,
  },
};
</script>

<style lang="css" scoped></style>

<template>
  <v-radio-group v-model="valueAttribute"
    :value-comparator="compareValue"
    class="mt-0 mb-0">
    <template v-slot:label>
      <div>{{ label }}</div>
    </template>
    <template v-for="radio in radioValues">
      <v-radio :key="radio.value" :value="radio.value">
        <template v-slot:label>
          <value-marketplace :value="radio"
            bind="label"
            class="d-flex col-6 pa-0" />
        </template>
      </v-radio>
    </template>
  </v-radio-group>
</template>
<script>
import AttributeBaseMixins from '@/mixins/attribute-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import LangBaseMixins from '@/mixins/lang-base';
import ValueMarketplace from '@/components/helper/ValueMarketplace';

const namespace = 'components.attribute.radio';
export default {
  mixins: [LangBaseMixins, AttributeBaseMixins, CompletudeBaseMixins],
  name: 'attributeSelection',
  props: {
    itemsValue: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    type: 'selection',
  }),
  components: {
    ValueMarketplace,
  },
  computed: {
    valueAttribute: {
      get() {
        const properties = this.item[this.property] || null;
        if (properties
          && typeof properties[this.keyAttr] !== 'undefined') {
          try {
            return properties[this.keyAttr].fr || properties[this.keyAttr];
          } catch (err) {
            this.$log.error(
              `${namespace}.valueattribute.${this.keyAttr}.cant.be.retrieved.datacomplex.correctly`,
            );
          }
        } else {
          try {
            return properties;
          } catch (err) {
            this.$log.error(
              `${namespace}.valueattribute.${this.keyAttr}.cant.be.retrieved.correctly`,
            );
          }
        }
        return null;
      },
      set(value) {
        const index = this.itemsValue.findIndex(v => v.id.slugify() === value.slugify());
        if (index === -1) {
          throw new TypeError(`${namespace}.valueattribute.cant.be.setted`);
        }
        try {
          const itemValue = { ...this.itemsValue[index] };
          const properties = this.item[this.property] || null;
          if (properties && typeof properties[this.keyAttr] !== 'undefined') {
            this.item[this.property][this.keyAttr] = itemValue.name;
          } else {
            this.item[this.property] = itemValue.name;
          }
        } catch (err) {
          this.$log.error(err);
          throw new TypeError(`${namespace}.valueattribute.have.failed`);
        }
      },
    },
  },
};
</script>
<template>
  <div>
    <div v-if="!hasRightActions || noDialog">{{ cleanValue(titleName.fr || titleName) }}</div>
    <v-edit-dialog :return-value="valueAttribute" large lazy v-else @save="saveInline">
      <div>{{ cleanValue(titleName.fr || titleName) }}</div>
      <core-error
        v-if="item.errors"
        :property="property"
        :errors="item.errors[keyAttr || property]"
      />
      <template v-slot:input>
        <v-autocomplete
          v-bind:value="valueAttribute"
          v-on:change="newObject = $event"
          :items="selectValues"
          :label="labelName || keyAttr"
          :hint="attribute.getHelp()"
          :multiple="multipleSelection"
          item-text="name"
          item-value="id"
          menu-props="auto"
          persistent-hint
          :value-comparator="compareValue"
          :readonly="readonly"
          @update:error="showError"
        >
          <template v-slot:selection="{ item }">
            <v-chip>
              <value-marketplace v-model="item" />
            </v-chip>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-simple-checkbox
              v-if="multipleSelection"
              v-on="on"
              :disabled="attrs.disabled"
              :value="attrs.inputValue"
              :ripple="attrs.ripple"
            />
            <value-marketplace v-model="item" />
          </template>
        </v-autocomplete>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
/* eslint max-len:0 */
import DialogBaseMixins from '@/mixins/dialog-base';
import ProfileMixins from '@/mixins/profile-base';
import LangBaseMixins from '@/mixins/lang-base';
import ValueMarketplace from '@/components/helper/ValueMarketplace';

export default {
  mixins: [LangBaseMixins, DialogBaseMixins, ProfileMixins],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValueMarketplace,
  },
  data: () => ({
    type: 'selection',
  }),
  computed: {
    itemsKey: () => undefined,
  },
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  methods: {
    cleanValue(value) {
      if (Array.isArray(value)) {
        return value.join(',');
      }
      return value;
    },
  },
};
</script>

<!-- Using the BEM convention -->
<style></style>

<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" md="10" align="center">
        <v-chip :color="getColor(product?.status?.pim)" dark small>
          {{ getMessage(product?.status?.pim) }}
        </v-chip>
        <v-chip :color="getColor(product?.status?.marketplace, 'marketplace')" dark small>
          {{ getMessage(product?.status?.marketplace, "marketplace") }}
        </v-chip>
        <v-chip :color="product.active ? 'red' : 'black'" dark small>
          {{ product.active ? "active" : "non-active" }}
        </v-chip>
        <v-chip color="green" dark small v-if="isSuperAdmin"> pim.id:{{ product.id }} </v-chip>
        <v-chip color="green" dark small v-if="isSuperAdmin"> pim.remotecode:{{ product.remoteCode }} </v-chip>
        <v-chip
          v-if="typeof product.infos !== 'undefined' && product.infos.att_legacy_r"
          color="yellow"
          dark
          small
        >
          theagent.id:{{ product.infos.att_legacy_r }}
        </v-chip>
        <v-chip color="orange" dark small v-if="isSuperAdmin"> pim.createdat:{{ product.createdAt }} </v-chip>
        <v-chip color="orange" dark small> pim.updatedat:{{ product.updatedAt }} </v-chip>
      </v-col>
      <v-col v-if="listOptions.length > 1 && product" cols="6" md="1" align="center">
        <dialog-list-options
          v-model="dialogListOption"
          :list-options="listOptions"
          :current="product"
          x-large
        >
          <template v-slot:title>
            {{ product.name }}<span v-if="product.sealed"> (data sealed)</span> </template
          >
        </dialog-list-options>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="product.name"
          :rules="textRules"
          counter="255"
          :label="$t('header.product.name')"
          :append-icon="userCanAccess ? undefined : 'mdi-cancel'"
          :readonly="!userCanAccess"
          required
          :loading="isCompletudeRequired('name', currentIssues)"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model="product.from"
          :items="states"
          item-text="name"
          item-value="key"
          :label="$t('header.product.from')"
          hint="Choose your country"
          :loading="isCompletudeRequired('from', currentIssues)"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="product.brandId"
          :append-icon="userCanAccess ? undefined : 'mdi-cancel'"
          :readonly="!userCanAccess"
          :items="brands"
          hide-details
          item-text="name"
          item-value="id"
          :label="$t('header.product.brand')"
          hint="Select your brand"
          :loading="isCompletudeRequired('brandId', currentIssues)"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="product.classificationId"
          :items="classifications"
          :append-icon="userCanAccess ? undefined : 'mdi-cancel'"
          :readonly="!userCanAccess"
          hide-details
          item-text="nameI18n"
          item-value="id"
          :label="$t('header.product.classification')"
          hint="Select your classification "
          :loading="isCompletudeRequired('classificationId', currentIssues)"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="product.distributorIds"
          :items="distributors"
          menu-props="auto"
          hide-details
          item-text="name"
          item-value="id"
          :label="$t('header.product.distributor')"
          hint="Pick your distributor"
          multiple
          chips
          persistent-hint
          cache-items
          deletable-chips
          :loading="isCompletudeRequired('distributorIds', currentIssues)"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <attribute-channel
          v-if="typeof product.channelIds !== 'undefined'"
          ref="channel"
          :item="product"
          property="channelIds"
        >
        </attribute-channel>
      </v-col>
    </v-row>
    <dialog-json :item="product" property="infos" tabMode vertical> </dialog-json>
  </v-container>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Product } from '@/models';
import ProfileBaseMixins from '@/mixins/profile-base';
import ChipBaseMixins from '@/mixins/chip-base';
import CompletudeBaseMixins from '@/mixins/completude-base';
import ConfigBaseMixins from '@/mixins/config-base';

export default {
  name: 'ProductEdit',
  mixins: [ProfileBaseMixins, ChipBaseMixins, CompletudeBaseMixins, ConfigBaseMixins],
  props: {
    value: {
      type: [Product, Object],
      default: () => Product.create(),
    },
  },
  data: () => ({
    dialogListOption: false,
    listOptionsDefault: [
      {
        name: 'Default',
      },
      {
        name: 'Duplicate this product 👯‍♀️👯‍♂️',
        fields: ['id', 'name'],
        store: 'product/duplicate',
        scope: 'pimapp.v2:pim:product:duplicate:write',
        payload: {
          children: false,
        },
      },
      {
        name: 'Rename this product',
        fields: ['id', 'name'],
        store: 'product/rename',
        scope: 'pimapp.v2:pim:product:rename:write',
        payload: {
          children: true,
        },
      },
    ],
  }),
  mounted() {
    this.$store.dispatch('api/attribute/list');
  },
  watch: {
    product() {
      this.$emit('input', this.product);
    },
  },
  computed: {
    ...mapGetters('form/rules', { textRules: 'text' }),
    listOptions() {
      return this.listOptionsDefault.filter(o => this.hasRight(o.scope || null));
    },
    product: {
      get() {
        // update from database all info datas
        const { infos = {} } = this.value;
        const attributes = this.$store.getters['api/attribute/productAttribute'];
        if (attributes.length > 0) {
          Vue.set(
            this.value,
            'infos',
            attributes.reduce((r, a) => {
              if (typeof r[a.name] === 'undefined') {
                // eslint-disable-next-line no-param-reassign
                Vue.set(r, a.name, '');
              }
              return r;
            }, infos),
          );
        }
        return this.value;
      },
      set(value) {
        this.value = value;
      },
    },
    userCanAccess() {
      return this.hasRight('pimapp.v2:pim:product:edit:write');
    },
  },
  methods: {
  },
};
</script>

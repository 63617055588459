<template>
  <v-dialog v-model="dialog" persistent max-width="960px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!disableButton"
        fab
        dark
        color="green"
        x-small
        class="mx-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
      <attribute-title-lang
        v-else
        v-model="valuesLang"
        v-bind="attrs"
        v-on="on"
      >
      </attribute-title-lang>
    </template>
    <v-card>
      <v-card-title>Attribute '{{attribute.label[currentLang]}}'</v-card-title>
      <v-row>
        <v-col cols="6">
          <v-textarea v-model="currentValue" filled label="Original value" auto-grow></v-textarea>
        </v-col>
        <v-col cols="6">
          <attribute-title-lang v-model="valuesLang"> </attribute-title-lang>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="compositions"
            disable-filtering
            disable-pagination
            disable-sort
            hide-default-footer
          >
            <template v-slot:item.unit="{ item }">
              <v-edit-dialog :return-value.sync="item.unit" large lazy>
                {{ item.unit }}
                <template v-slot:input>
                  <v-select
                    v-model="item.unit"
                    :items="unitsList"
                    label="Edit unit"
                    single-line
                  ></v-select>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.valueInt="{ item }">
              <v-edit-dialog :return-value.sync="item.valueInt" large lazy>
                {{ item.valueInt }}
                <template v-slot:input>
                  <v-text-field
                    v-model="item.valueInt"
                    :rules="percentRules"
                    label="Edit value"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.valueString="{ item }">
              <v-edit-dialog :return-value.sync="item.valueString" large lazy>
                {{ item.valueString }}
                <template v-slot:input>
                  <v-autocomplete
                    :chips="chips"
                    :filled="filled"
                    :items="itemsValue"
                    :label="label || keyAttr"
                    :multiple="multipleSelection"
                    :hint="attribute.getHelp()"
                    item-text="name"
                    item-value="id"
                    menu-props="auto"
                    persistent-hint
                    v-model="item.valueString"
                  >
                     <template v-slot:selection="{ item }">
                      <v-chip>
                        <value-marketplace v-model="item" />
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item, on, attrs }">
                      <v-simple-checkbox
                        v-if="multipleSelection"
                        v-on="on"
                        :disabled="attrs.disabled"
                        :value="attrs.inputValue"
                        :ripple="attrs.ripple"
                      />
                      <value-marketplace v-model="item" />
                    </template>
                  </v-autocomplete>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="removeComposition(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="3"> </v-col>
        <v-col cols="7"> </v-col>
        <v-col cols="2">
          <v-btn color="green" fab dark x-small justify-center @click="addComposition" class="mx-1">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveComposition">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Attribute } from '@/models';
import { Tokenizer, Compositionizer } from '@/utils/semantic';
import LangBaseMixins from '@/mixins/lang-base';
import ValueMarketplace from '@/components/helper/ValueMarketplace';
import { getFrenchValue, getLangValue, buildRawValue } from '@/utils/rawvalue';

const namespace = 'components.dialog.composition';

export default {
  name: 'attributeSelection',
  mixins: [LangBaseMixins],
  props: {
    value: {
      type: [Object, String],
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'Composition',
    },
  },
  components: {
    ValueMarketplace,
  },
  mounted() {
    let compositions = [];
    try {
      compositions = Compositionizer(Tokenizer(this.currentValue));
    } catch (err) {
      this.$log.error(err.message);
    }
    Vue.set(this, 'compositions', compositions);
  },
  computed: {
    ...mapGetters('form/rules', {
      percentRules: 'percent',
    }),
    attribute() {
      const attr = this.$store.getters['api/attribute/attribute'](this.type);
      // attr is an array with name: default
      if (attr && !Array.isArray(attr)) {
        return Attribute(attr, {
          entityTypes: this.$store.getters['config/attribute.typeEntities'],
          currentLang: this.currentLang || 'fr',
        });
      }
      return null;
    },
    /**
     * override itemsValue from core/attribute
     * but for now no slufigy is applied
     * @return {[type]} [description]
     */
    itemsValue() {
      // from src/components/core/attribute.vue
      if (typeof this.attribute.values === 'undefined') {
        return [];
      }
      let itemsValue = [];
      try {
        itemsValue = this.attribute.values.slice(0);
      } catch (err) {
        this.$log.error(`${namespace}.itemsvalue.value.itemsvalue.failed`);
      }
      return itemsValue.reduce((result, { value, classificationId = [], marketplaces = [] }) => {
        const raw = this.buildRawValue(value, classificationId, marketplaces);
        if (raw) {
          result.push(raw);
        }
        return result;
      }, []);
    },
    currentValue: {
      get() {
        return typeof this.value === 'object' ? this.value.fr : this.value;
      },
      set(value) {
        if (typeof value === 'string') {
          this.compositions = Compositionizer(Tokenizer(value));
        } else if (typeof value === 'object') {
          this.compositions.push(value);
        } else {
          throw new TypeError(`${namespace}.issue on currentvalue`);
        }
      },
    },
    selectValues() {
      // https://github.com/vuejs/eslint-plugin-vue/blob/master/docs/rules/no-side-effects-in-computed-properties.md
      // .slice makes a copy of the array, instead of mutating the orginal
      const itemsValue = this.itemsValue.slice(0);
      // add default value
      itemsValue.unshift({
        name: 'Empty value',
        id: '',
      });

      return itemsValue.map(i => ({
        id: i.id,
        name: i.name.fra || i.name.fr || i.name,
      }));
    },
    multipleSelection() {
      const multipleFields = ['Couleur spécifique', 'att_couleur_detail_rc'];
      return multipleFields.includes(this.property) || multipleFields.includes(this.keyAttr);
    },
    valuesLang() {
      return Object.keys(this.langs).reduce((result, langIco) => {
        const langLegacy = this.langs[langIco];
        // eslint-disable-next-line no-param-reassign
        result[langLegacy] = this.compositions
          .reduce((r, s) => {
            const index = this.attribute.values.findIndex(({ value }) => getFrenchValue(value) === s.valueString);
            if (index !== -1) {
              const attributeValue = this.attribute.values[index]?.value || null;
              r.push(`${s.valueInt}${s.unit} ${getLangValue(attributeValue, langLegacy)}`);
            } else {
              r.push(`${s.valueInt}${s.unit} ${s.valueString}`);
            }
            return r;
          }, [])
          .join(', ');
        return result;
      }, {});
    },
  },
  data: () => ({
    isValid: true,
    dialog: false,
    compositions: [],
    headers: [
      {
        text: 'Value',
        align: 'start',
        sortable: false,
        value: 'valueInt',
      },
      { text: 'Units', value: 'unit' },
      { text: 'Type of composition', value: 'valueString' },
      { text: 'Actions', value: 'actions' },
    ],
    unitsList: ['%', 'cm', 'cl', 'dl', 'dm', 'mm', ''],
  }),
  methods: {
    buildRawValue(value, classificationId, marketplaces = []) {
      const buildValue = buildRawValue(this.$store.getters['product/product'].classificationId || null, this.currentLang);
      return buildValue(value, classificationId, marketplaces);
    },
    addComposition() {
      this.$log.debug('mixin.editbase.addcomposition.started');
      this.compositions.push({ unit: '%', valueInt: 0, valueString: 'example' });
    },
    removeComposition(item) {
      this.$log.debug('mixin.editbase.deleteitem.started', item);
      // eslint-disable-next-line max-len
      const index = this.compositions.findIndex(
        c => c.unit === item.unit && c.valueString === item.valueString,
      );
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this item?')) {
        this.compositions.splice(index, 1);
      }
    },
    saveComposition() {
      this.$emit('input', this.valuesLang);
      this.$emit('save', this.valuesLang);
      this.dialog = false;
    },
  },
};
</script>

<template>
  <keep-alive>
    <component
      :is="component"
      :keyAttr="keyAttr"
      v-model="item[property]"
      :property="property"
      :label="labelAttribute"
      :attribute="attribute"
      :item="item"
      :class="`ta-template-${template}`"
      :filled="filled"
      :itemsValue="itemsValue"
      :item-value="itemValue"
      v-if="component"
    >
      <template v-slot:label>
        <slot name="label"></slot>
      </template>
    </component>
  </keep-alive>
</template>
<script>
import Vue from 'vue';
import ServiceTemplate from '@/services/template';
import AttributeBaseMixins from '@/mixins/attribute-base';
import LangBaseMixins from '@/mixins/lang-base';
import { buildRawValue } from '@/utils/rawvalue';

const namespace = 'components.core.attribute';
/**
 * deprecated remove all property, key attr and  item from here
 * maintains only in core attribute and not inside attribute/*.vue
 */
export default {
  mixins: [AttributeBaseMixins, LangBaseMixins],
  props: {
    customComponent: {
      type: Object,
      required: false,
    },
  },
  name: 'attribute',
  computed: {
    component() {
      if (!(this.keyAttr || this.property) || !this.template) {
        return () => import('../attribute/default');
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      return () => import(`../attribute/${this.template}`).catch(() => import('../attribute/default'));
    },
    labelAttribute() {
      if (
        !this.attribute
        || typeof this.attribute.label === 'undefined'
        || typeof this.attribute.name === 'undefined'
      ) {
        return false;
      }
      // internationalization can be enabled for this property
      if (
        typeof this.attribute.label[this.currentLang] === 'undefined'
        && typeof this.attribute.label.fr === 'undefined'
      ) {
        return this.attribute.label;
      }
      if (typeof this.attribute.marketplaces === 'undefined' || !this.attribute.marketplaces) {
        return this.attribute.label[this.currentLang] || this.attribute.label.fr;
      }
      return `${this.attribute.label[this.currentLang]
        || this.attribute.label.fr} (marketplaces: ${this.attribute.marketplaces.join(', ')})`;
    },
    // merge with method with labelattribute and a watch
    itemsValue() {
      if (!this.attribute.values || this.attribute.values.length === 0) {
        return [];
      }
      const debug = this.$store.getters['settings/theagent'].sillyMode;
      // https://github.com/vuejs/eslint-plugin-vue/blob/master/docs/rules/no-side-effects-in-computed-properties.md
      // .slice makes a copy of the array, instead of mutating the orginal
      let itemsValue = [];

      try {
        itemsValue = this.attribute.values.slice(0);
      } catch (err) {
        this.$log.error(`${namespace}.itemsvalue.value.itemsvalue.failed`);
        if (debug) this.$log.error(err);
        if (debug) this.$log.error(err);
      }
      return itemsValue.reduce((result, { value, classificationId = [], marketplaces = [] }) => {
        const raw = this.buildRawValue(value, classificationId, marketplaces);
        if (raw) {
          result.push(raw);
        }
        return result;
      }, []);
    },
    debug() {
      return this.$store.getters['settings/theagent'].sillyMode;
    },
    template() {
      return ServiceTemplate.getTemplate(this.attribute, { debug: this.debug });
    },
  },
  created() {
    if (
      this.hasDataModel
      && this.keyAttr
      && typeof this.item.dataJson[this.keyAttr] === 'undefined'
    ) {
      Vue.set(this.item.dataJson, this.keyAttr, '');
    } else if (this.property) {
      if (typeof this.item[this.property] === 'undefined') {
        Vue.set(this.item, this.property, this.keyAttr ? {} : '');
      }
      if (this.keyAttr && typeof this.item[this.property][this.keyAttr] === 'undefined') {
        Vue.set(this.item[this.property], this.keyAttr, '');
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch('api/attribute/list');
  },
  methods: {
    buildRawValue(value, classificationId, marketplaces = []) {
      const buildValue = buildRawValue(this.$store.getters['product/product'].classificationId || null, this.currentLang);
      return buildValue(value, classificationId, marketplaces);
    },
  },
};
</script>

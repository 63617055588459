<template>
  <div v-if="value" class="d-flex" style="width: 100%">
    <div>
      {{ value[bind] }}
    </div>
    <div class="ml-auto" v-if="value?.marketplaces?.length > 0">
      <template
        v-for="marketplace in value.marketplaces">
        <span class="ta-icon-marketplaces" :key="marketplace">
          <template v-for="alpha in marketplace">
            <v-icon
              small
              color="pink darken-1"
              :key="alpha">
              mdi-alpha-{{ alpha.toLowerCase() }}-box
            </v-icon>
          </template>
        </span>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ValueMarketplace',
  props: {
    value: {
      type: Object,
      required: true,
      default: null,
    },
    bind: {
      type: String,
      default: 'name',
    },
  },
};
</script>
